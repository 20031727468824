import { Suspense, useEffect } from 'react'
import TabsLayout from '~/src/components/utils/layout/Tabs/TabsLayout'
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined'
import RoutesEnum from '~/src/router/enums/routes.enum'
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined'
import useGetOrderTemplate from '~/src/components/orders/order-layout/styles/useGetOrderTemplate'
import { useTranslations } from '~/src/hooks/useTranslations'
import InPageLoader from '~/src/components/loader/InPageLoader'
import { Box } from '@mui/material'
import { useEoValue } from '~/src/hooks/useEoState'
import { IS_LOGGED_IN_SELECTOR } from '~/src/stores/authentication'
import AuthRequired from '~/src/components/auth/AuthRequired'
import ThankYouForYourOrderResponsiveDialog from '~/src/components/orders/ThankYouForYourOrderResponsiveDialog'
import { useRouterQuery } from '~/src/hooks/router/useRouterQuery'
import useResetStates from '~/src/hooks/useResetStates'
import SlideFromRight from '~/src/components/mui-wrappers/animations/motion/SlideFromRight'
import { Outlet, useLocation } from 'react-router-dom'
import useDialog from '~/src/hooks/useDialog'
import { DialogNameEnum } from '~/src/types/DialogNameEnum'
import useGetHeaderOffset from '~/src/hooks/layout/useGetHeaderOffset'

interface Props {
  isLoading?: boolean,
  useAuthGuard?: boolean,
}

const OrderLayout = ({ isLoading }: Props) => {
  const { present } = useDialog()
  const { pathname } = useLocation()
  const { t } = useTranslations()
  const { params: queryParams } = useRouterQuery()
  const isLoggedIn = useEoValue(IS_LOGGED_IN_SELECTOR)
  const { resetStates } = useResetStates()
  const style = useGetOrderTemplate()
  const { getOffset } = useGetHeaderOffset()

  const _getNavItems = () => {
    const navItems = [
      {
        label: t('orders.orderUpcoming'),
        value: 'upcoming',
        icon: <LocalShippingOutlinedIcon/>,
        iconPosition: 'start',
        href: `${RoutesEnum.ORDERS}/${RoutesEnum.UPCOMING}`
      },
      {
        label: t('orders.orderHistory'),
        value: 'orders',
        icon: <HistoryOutlinedIcon/>,
        iconPosition: 'start',
        href: `${RoutesEnum.ORDERS}/${RoutesEnum.HISTORY}`
      }
    ]

    if (!isLoggedIn) {
      return navItems.slice(0, -1)
    }

    return navItems
  }

  const _getCurrentPath = (path: string): string | null => {
    if (path.includes(RoutesEnum.HISTORY) || path.includes(RoutesEnum.TRACKER)) {
      return 'orders'
    }

    if (path.includes(RoutesEnum.UPCOMING)) {
      return 'upcoming'
    }

    return null
  }

  useEffect(() => {
    if (!queryParams['thank-you']) {
      return
    }

    present(ThankYouForYourOrderResponsiveDialog, DialogNameEnum.THANK_YOU_FOR_YOUR_ORDER, {
      open: true
    })
    resetStates(true)
  }, [ queryParams ])

  if (!isLoggedIn && !pathname.includes(RoutesEnum.UPCOMING)) {
    return <AuthRequired/>
  }

  return (
    <SlideFromRight
      pathname={'ORDERS'}
      transition={{
        duration: .5
      }}
    >
      <Box sx={style.getPageSx(getOffset())}>
        <TabsLayout
          title={t('orders.orders')}
          TabsProps={{
            navItems: _getNavItems(),
            onTabChange: _getCurrentPath,
            id: 'order_nav'
          }}
        >
          {isLoading && <InPageLoader/>}
          {!isLoading && (
            <Suspense>
              <Outlet/>
            </Suspense>
          )}
        </TabsLayout>
      </Box>
    </SlideFromRight>
  )
}

export default OrderLayout
